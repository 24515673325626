import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Dashboard from './Components/Dashboard';
import Navigation from './Components/Navigation';

function App() {

const { loginWithRedirect, user, isLoading,  } = useAuth0();

useEffect(()=>{  
if(!user && !isLoading){
  loginWithRedirect()
}
  
},[user])
const LoginButton = () => {

  return <button className='p-10 bg-white rounded-full w-60 text-xl' onClick={() => loginWithRedirect()}>Log In</button>;
};

  return (
    
    
    <div className='flex flex-row min-h-screen'>
      {user ? 
      <>
      <div className='flex flex-col w-1/4'>
        <Navigation/>
      </div>
      <div className='flex flex-col w-3/4'>
        {/* New Lawyers */}
        <div className=''>          
         <Dashboard />
        </div>
      </div>
      </>
      :
      <div className='mx-auto my-auto'>
        {LoginButton()}
      </div>
      }
    </div>

  );
}

// export const PRODUCTION_ENDPOINT = 'http://localhost:8080' //move to its own file later
export const PRODUCTION_ENDPOINT = 'https://injusticepa-restapi-e3e218589278.herokuapp.com' //move to its own file later
export default App;