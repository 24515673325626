import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useEffect } from 'react';
import { PRODUCTION_ENDPOINT } from '../App';
import axios from 'axios';

const Dashboard = () => {
const [pullLawyers, setPullLawyers] = useState(false)
const [data, setData] = useState([])
const {user,loginWithRedirect, isLoading, isAuthenticated} = useAuth0()
const [mappedData, setMappedData] = useState([])
const [reasons, setReasons] = useState({});

useEffect(()=>{  
    if(user && !isLoading && isAuthenticated){
      let url = PRODUCTION_ENDPOINT + '/api/lawyers/pending'
      let payload = {
        id: user.sub,
        token: user.app_metadata['roomkey-owner']
      }     
      axios.post(url, payload, {headers:{'Content-Type':'application/json'}}).then((res)=>{
        setData(res.data)
        console.log(res.data)
      }).catch((e)=>{
        
      })
    }else if((!user || !isAuthenticated) &&  !isLoading){
      loginWithRedirect()
    }
      
    },[user, pullLawyers])

    const handleApprove = (lawyer) => {
        const {id, firstName, lastName, stateLicensed, barNumber, specialty, firm, firmAddress, biography, phoneNumber} = lawyer
        const userReason = reasons[`${id}-${stateLicensed}`] 
        const isConfirmed = window.confirm(`Are you sure you want to APPROVE ${firstName}${lastName}?\n(Bar: ${barNumber}, State: ${stateLicensed})`);        
        if (isConfirmed) {            
            let url = PRODUCTION_ENDPOINT + "/api/admin/lawyers/approve"
            let postData = {validateUser:{id: user.sub, token: user.app_metadata["roomkey-owner"]}, 
            lawyerSettings: {
                id: id,
                stateLicensed: stateLicensed,
                barNumber: barNumber,
                specialty: specialty,
                firm: firm,
                firmAddress: firmAddress,
                biography: biography,
                phoneNumber: phoneNumber
            }}
            axios.post(url,postData,{headers:{"Content-Type":'application/json'}}).then((res)=>{
                setPullLawyers(!pullLawyers)
            }).catch((e)=>{})
        } else {
            console.log("Approval action was canceled.");
        }        
        
    }

    const handleDeny = (lawyer) => {
        const {id, firstName, lastName, stateLicensed, barNumber, specialty, firm, firmAddress, biography, phoneNumber} = lawyer
        const userReason = reasons[`${id}-${stateLicensed}`] 
        const isConfirmed = (userReason) ?
             window.confirm(`Are you sure you want to DENY ${firstName}${lastName}?\n(Bar: ${barNumber}, State: ${stateLicensed}, Reason: ${userReason})`)
             :alert(`Please enter a reason`);
        if (isConfirmed) {            
            let url = PRODUCTION_ENDPOINT + "/api/admin/lawyers/deny"
            let postData = {                
                adminId: user.sub,
                token: user.app_metadata["roomkey-owner"],
                id: id,
                stateLicensed: stateLicensed,
                barNumber: barNumber,
                specialty: specialty,
                firm: firm,
                firmAddress: firmAddress,
                biography: biography,
                phoneNumber: phoneNumber,
                reason: userReason                
            }
            axios.post(url,postData,{headers:{"Content-Type":'application/json'}}).then((res)=>{
                setPullLawyers(!pullLawyers)
            }).catch((e)=>{})
            // Implement your logic here.
        } else {
            console.log("Deny action was canceled.");
        }  
        
        // Implement your logic here.
    }

    function handleReasonChange(e, userId, stateLicensed) {
        setReasons(prevReasons => ({
            ...prevReasons,
            [userId+"-"+stateLicensed]: e.target.value
        }));
    }

    function mapData() {
        if (data) {
            return data.map(user => (
                <div key={user.id} className="flex justify-between items-center p-4 bg-white border rounded-md shadow">
                    <div className="flex-1 w-2/3">
                        <div className="text-lg font-bold">{user.firstName} {user.lastName}</div>
                        <div className="text-sm text-gray-600">{user.email}</div>
                        <div className="mt-2">
                            <span className="mr-4"><strong>State:</strong> {user.stateLicensed}</span>
                            <span className="mr-4"><strong>Bar Number:</strong> {user.barNumber}</span>
                            <span><strong>Specialty:</strong> {user.specialty}</span>
                        </div>
                    </div>
                    <div className="flex flex-col space-y-4 w-1/3">
                        <input 
                            type="text"
                            placeholder="Enter reason..."
                            value={reasons[`${user.id}-${user.stateLicensed}`] || ''}
                            onChange={e => handleReasonChange(e, user.id, user.stateLicensed)}
                            className="px-3 py-2 border rounded-md"
                        />
                        <div className="grid grid-cols-2 space-x-4">
                            <button 
                                onClick={() => handleApprove(user)}
                                className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                            >
                                Approve
                            </button>
                            <button 
                                onClick={() => handleDeny(user)}
                                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                            >
                                Deny
                            </button>
                        </div>
                    </div>
                </div>
            ));
        }
    }


    return (
        <div className="p-8">
            <h2 className="text-2xl mb-4 text-black text-center font-bold">Admin Dashboard</h2>
            <div className="bg-gray-100 rounded-lg p-4 space-y-4">
                <h3 className="text-2xl mb-4 text-black">Pending Lawyers</h3>
                {mapData()}
            </div>
        </div>
    );
}

export default Dashboard;
