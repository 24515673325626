// Components/Navigation.js

import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';

function Navigation() {

const{user,logout, loginWithRedirect }= useAuth0()

    return (
        <div className="bg-[rgba(0,137,231,255)] h-full p-8">
            <h2 className="text-2xl mb-4">Welcome, {user.user_metadata['first_name']}!</h2>
            <ul className="space-y-2">
                <li>
                    <a href="#" className="block p-2 rounded bg-white hover:bg-gray-100 text-center">Dashboard</a>
                </li>
                <li>
                    {(user) ?
                    <button className="block p-2 rounded bg-white hover:bg-gray-100 w-full" onClick={(e)=>logout()}>Logout</button>                
                    :
                    <button className="block p-2 rounded bg-white hover:bg-gray-100 w-full" onClick={(e)=>loginWithRedirect()}>Login</button>                
                    }
                </li>
                {/* <li>
                    <a href="#" className="block p-2 rounded bg-white hover:bg-gray-100">Profile</a>
                </li>
                <li>
                    <a href="#" className="block p-2 rounded bg-white hover:bg-gray-100">Logout</a>
                </li> */}
            </ul>
        </div>
    );
}

export default Navigation;
